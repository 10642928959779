<template>
  <div class="i-product-hero" @click.stop="prepareBrowseProduct">
    <product-added-dialog-ok :showProductAddedConfirmation="showProductAddedConfirmation"  />
    <div class="i-product-hero__fav">
      <div
        class="badge badge--outline-gold badge--bold badge--rounded badge--sm badge--ico"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.665 5.44725C13.6229 5.32531 13.5463 5.2182 13.4446 5.13892C13.3428 5.05964 13.2202 5.01158 13.0917 5.00059L9.29836 4.44725L7.59836 1.00059C7.54377 0.887871 7.45853 0.792813 7.35242 0.726301C7.2463 0.659788 7.1236 0.624512 6.99836 0.624512C6.87312 0.624512 6.75041 0.659788 6.6443 0.726301C6.53818 0.792813 6.45295 0.887871 6.39836 1.00059L4.69836 4.44058L0.905024 5.00059C0.781639 5.01812 0.66564 5.06989 0.570191 5.15002C0.474742 5.23015 0.403664 5.33543 0.365024 5.45392C0.329654 5.5697 0.32648 5.69293 0.355843 5.81038C0.385205 5.92783 0.445996 6.03507 0.531691 6.12059L3.28502 8.78725L2.61836 12.5739C2.59456 12.6989 2.60702 12.8281 2.65427 12.9462C2.70152 13.0644 2.78159 13.1665 2.88502 13.2406C2.98584 13.3127 3.10474 13.3552 3.22839 13.3634C3.35204 13.3717 3.47554 13.3453 3.58502 13.2873L6.99836 11.5073L10.3984 13.2939C10.4919 13.3467 10.5976 13.3743 10.705 13.3739C10.8462 13.3744 10.984 13.3301 11.0984 13.2473C11.2018 13.1732 11.2819 13.071 11.3291 12.9529C11.3764 12.8348 11.3888 12.7056 11.365 12.5806L10.6984 8.79392L13.4517 6.12725C13.5479 6.0457 13.6191 5.93852 13.6568 5.81815C13.6946 5.69778 13.6974 5.56917 13.665 5.44725ZM9.56502 8.11392C9.48684 8.18954 9.42835 8.28315 9.39465 8.38657C9.36095 8.48999 9.35307 8.60009 9.37169 8.70725L9.85169 11.5006L7.34502 10.1673C7.24857 10.1159 7.14097 10.089 7.03169 10.089C6.92241 10.089 6.81481 10.1159 6.71836 10.1673L4.21169 11.5006L4.69169 8.70725C4.71031 8.60009 4.70243 8.48999 4.66873 8.38657C4.63504 8.28315 4.57654 8.18954 4.49836 8.11392L2.49836 6.11392L5.30502 5.70725C5.41302 5.69223 5.51569 5.65095 5.60402 5.58702C5.69236 5.52309 5.76366 5.43848 5.81169 5.34059L6.99836 2.80059L8.25169 5.34725C8.29972 5.44514 8.37102 5.52976 8.45936 5.59369C8.54769 5.65761 8.65036 5.6989 8.75836 5.71392L11.565 6.12059L9.56502 8.11392Z"
            fill="#C48F0E"
          />
        </svg>
        Favorito
      </div>
    </div>
    <div class="i-product-hero__overlay"></div>

    <img class="i-product-hero__cover" :src="product.photo" alt="" />
    <div class="i-product-hero__content">
      <div class="i-product-hero__title">
        {{ product.name }}
      </div>
      <div class="i-product-hero__footer">
        <div class="i-product-hero__price">
          {{ $utils.formatMoney(product.price) }}
        </div>
        <div class="i-product-hero__actions" @click="addProduct('Constructor - Banner Grid')">
          <template v-if="!added">
            <loading-button v-if="loading" :class="'button-loading--sm'" />
            <div
              v-else
              class="button button--light button--sm button--ico"
              @click.stop="addProduct('Constructor - Banner Grid')"
            >
              <img src="../../../assets/img/ico/ico-add.svg" alt="" />
              Añadir
            </div>
          </template>
          <div
            v-else
            class="i-product-actions__button i-product-actions__button--delete"
          >
            <img src="../../../assets/img/ico/ico-check-grey.svg" alt="" />
            Añadido
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      :show="showProductAddedDialog"
      @close="showProductAddedDialog = false"
      target-class="modal__card--mini"
    >
      <product-added-to-list/>
    </modal-dialog>
  </div>
</template>

<script>
import Product from "./Mixins/Product";
import LoadingButton from "../../../components/LoadingButton";
import ModalDialog from "../../../components/ui/ModalDialog";
import ProductAddedToList from "../../../components/ProductAddedToList";
import ProductAddedDialogOk from "../../../components/ProductAddedDialogOk";

export default {
  name: "ProductCardHero",
  mixins: [Product],
  props: {
    product: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showProductAddedConfirmation: false,
    };
  },
  components: {
    LoadingButton,
    ModalDialog,
    ProductAddedToList,
    ProductAddedDialogOk
  },
  methods: {
    mixpanelClickOnElementConstructor() {
      window.mixpanel.track("Click element constructor", {
        "Name": this.product.name,
        "Section": this.section.title,
        "Section number": this.section.id,
        "Type": "Banner - Grid",
        "Content": "Ecommerce"
      });
    },
    prepareBrowseProduct()
    {
      this.$emit('click');
      this.mixpanelClickOnElementConstructor();
      this.browseProduct();
    }
  }
};
</script>
